import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CustomRating from './CustomRating'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 117,
    padding: '0 15px',
    textAlign: 'left'
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  timeago: {
    fontSize: 12,
    color: '#A09FA0'
  },
  comment: {
    marginTop: 5,
    maxHeight: 96,
    overflow: 'hidden',
    marginBottom: 5,
    fontWeight: 400,
    fontSize: 14,
    color: '#ffffff'
  },
  user: {
    fontSize: 12,
    color: '#A09FA0'
  }
}))

const ReviewItem = ({ review }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <CustomRating rating={review.rating} />
        <span className={classes.timeago}>{review.timeago}</span>
      </div>
      <div className={classes.comment}>{review.comment}</div>
      <div className={classes.user}>{review.username}</div>
    </div>
  )
}

ReviewItem.propTypes = {
  review: PropTypes.object.isRequired
}

export default ReviewItem
