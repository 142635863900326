import React, {useContext, useEffect, useState} from 'react';
import Image from 'next/image';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Grid} from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {Skeleton} from '@material-ui/lab';

import {PageContext} from 'src/context';

import CustomRating from './CustomRating';
import ReviewItem from './ReviewItem';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.primary.dark,
    color: '#ffffff',
    maxHeight: 320,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20
    }
  },
  details: {
    alignSelf: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    padding: '15px 0 0',
    [theme.breakpoints.down('sm')]: {
      transitionTimingFunction: 'ease-in',
      transition: '0.25s',
      transform: 'translateY(50%)',
      minHeight: 132,
      opacity: 0
    }
  },
  containerTr: {
    [theme.breakpoints.down('sm')]: {
      transitionTimingFunction: 'ease-out',
      transition: '1s',
      transform: 'translateY(0)',
      opacity: 1
    }
  },
  track: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
    willChange: 'transform, transition'
  },
  item: {
    transformStyle: 'preserve-3d',
    backfaceVisibility: 'hidden',
    textAlign: 'center'
    // margin: '0 -15px'
  },
  title: {
    fontSize: 22,
    fontWeight: 500
  },
  desc: {
    fontSize: 12,
    color: '#A09FA0'
  },
  arrowIcon: {
    position: 'absolute',
    top: 'calc(50% - 13px)',
    border: '1px solid #ffffff',
    borderRadius: 100,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  leftArrow: {
    left: -35
  },
  rightArrow: {
    right: -35
  },
  buttonGroup: {
    position: 'relative',
    top: '-100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute'
    }
  },
  buttonGroupTr: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative'
    }
  },
  dotsGroup: {
    padding: 0,
    margin: 0,
    textAlign: 'center'
  },
  dot: {
    color: '#869791',
    padding: 5,
    zIndex: 1,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  activeDot: {
    color: '#5DF730'
  },
  link: {
    color: '#ffffff'
  },
  fullWidth: {
    marginLeft: '-50%',
    marginRight: '-50%',
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-3%',
      marginRight: '-3%'
    }
  }
}));

const Index = ({reviews, fullWidth}) => {
  const classes = useStyles();
  const [showReviews, setShowReviews] = useState(false);
  const [showAllReviews, setShowAllReviews] = useState(false);
  // const deviceType = 'desktop';
  const {isMobileView, isTabletView} = useContext(PageContext);
  const deviceType = isMobileView
    ? 'mobile'
    : isTabletView
      ? 'tablet'
      : 'desktop';

  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(() => {
    setTimeout(() => setShowReviews(true), 1000);
  }, [showReviews]);

  if (!reviews) {
    return null;
  }

  const ButtonGroup = ({next, previous, goToSlide, ...rest}) => (
    <div
      className={clsx(
        classes.buttonGroup,
        showReviews && classes.buttonGroupTr
      )}
    >
      <ChevronLeftIcon
        className={clsx(classes.arrowIcon, classes.leftArrow)}
        fontSize='small'
        onClick={() => {
          previous();
          setShowAllReviews(true);
        }}
      />
      <ChevronRightIcon
        className={clsx(classes.arrowIcon, classes.rightArrow)}
        fontSize='small'
        onClick={() => {
          next();
          setShowAllReviews(true);
        }}
      />
    </div>
  );
  const CustomDot = ({index, onClick, active, ...rest}) => (
    <FiberManualRecordIcon
      onClick={e => {
        onClick();
        setShowAllReviews(true);
        e.preventDefault();
      }}
      className={clsx(classes.dot, active && classes.activeDot)}
    />
  );
  return (
    <div className={clsx(classes.root, fullWidth && classes.fullWidth)}>
      <Container>
        <Grid container justifyContent='space-around'>
          <Grid item xs={12} sm={2} className={classes.details}>
            <div className={classes.title} align='center'>
              {reviews.title}
            </div>
            <div align='center'>
              <CustomRating rating={reviews.averageRating} size='regular' />
            </div>
            <div className={classes.desc} align='center'>
              Based on{' '}
              <a
                href='https://www.reviews.io/company-reviews/store/probemas-com'
                className={classes.link}
              >
                {reviews.totalReviews} reviews
              </a>
            </div>
            <div align='center'>
              <HiddenCss smDown>
                <Image
                  src='/images/reviewsio-logo--white.png'
                  width={136}
                  height={22}
                  priority
                  alt='reviewsio'
                />
              </HiddenCss>
            </div>
          </Grid>
          <Grid item xs={10} sm={8}>
            <Carousel
              swipeable
              draggable
              responsive={responsive}
              infinite
              showDots
              ssr
              arrows={false}
              autoPlay={false}
              keyBoardControl={false}
              shouldResetAutoplay={false}
              // transitionDuration={500}
              containerClass={clsx(
                classes.container,
                showReviews && classes.containerTr
              )}
              sliderClass={classes.track}
              itemClass={classes.item}
              deviceType={deviceType}
              renderButtonGroupOutside
              customButtonGroup={<ButtonGroup />}
              dotListClass={classes.dotsGroup}
              customDot={<CustomDot />}
              renderDotsOutside
              // customRightArrow={<ArrowForwardIosIcon className={classes.rightArrow}/>}
              // customLeftArrow={<ArrowBackIosIcon className={classes.leftArrow}/>}
            >
              {reviews.reviews.map((review, index) => {
                // lazy load unused comments to avoid huge DOM elements count
                if (index > 2) {
                  return (
                    <React.Fragment key={review.comment}>
                      {showAllReviews ? (
                        <ReviewItem review={review} />
                      ) : (
                        <Skeleton />
                      )}
                    </React.Fragment>
                  );
                }

                return <ReviewItem review={review} key={review.comment} />;
              })}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Index.propTypes = {};

export default Index;
