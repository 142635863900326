import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  icon: {
    marginRight: 3,
    display: 'block'
  },
  0: {
    backgroundColor: '#dcdce6'
  },
  1: {
    backgroundColor: '#f64028'
  },
  2: {
    backgroundColor: '#fa852a'
  },
  3: {
    backgroundColor: '#ffcf2e'
  },
  4: {
    backgroundColor: '#73cf17'
  },
  5: {
    backgroundColor: '#00b67a'
  }
}));

const CustomRating = ({ rating, size }) => {
  const classes = useStyles();
  const StarIcon = ({ rating }) => (
    <img
      src="/images/single-star-transparent.svg"
      // priority
      alt="star-icon"
      width={size === 'regular' ? 27 : 20}
      height={size === 'regular' ? 27 : 20}
      className={classes[Math.ceil(parseFloat(rating))]}
    />
  );
  return (
    <Rating
      value={rating}
      precision={0.5}
      readOnly
      className={clsx(classes.root)}
      icon={<StarIcon rating={rating} />}
      emptyIcon={<StarIcon rating={0} />}
      classes={{ icon: classes.icon }}
    />
  );
};

CustomRating.propTypes = {
  rating: PropTypes.number,
  size: PropTypes.oneOf(['regular', 'small'])
};

export default CustomRating;
